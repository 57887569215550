export default { 
    navTitle: 'Enterprise Search',
    history_text: '历史',    
    profile_text: 'Profile', 
    preferences_text: '偏好设置',
    user_name: '用户名',
    signout_text: '退出登录',
    search_text: '搜索',
    loadingText: '无论公司，数据库，文档管理系统如何，都可以从公司内部的任何位置以任何格式访问所有数据。', 
    synonyms_heading: '同义词',
    semantics_heading: '语义学',
    date_heading: '日期',
    about_text: '关于',
    results_text:"结果",
    sort_by_text: '排序:',
    group_by_text: '通过...分组 :',
    relevance_list_text: '关联',
    ascending_order_text: '升序',
    descending_order_text: '降序',
    explore_text: '探索',
    each_documnet_title: '(个人电脑) 预测数据库（3个结果）',
    publish_text: '已发表',
    source_text: '资源',
    file_text: '文件',
    nodata_text_block_text: '没有与任何文件相符的搜寻结果',
    nodata_text_first_line: '尝试其他关键字',
    nodata_text_second_line: '确保所有单词拼写正确',
    nodata_match_text:'没有符合的',
    box_loading_text: '载入中...', 
    field_search_text: '现场搜索', 
    fieldsearch_button_text: '搜索', 
    search_box_text: '搜索',
    advanced_search_text: '高级搜索',
    clear_all_text: '全部清除',
    history_id_text: 'ID',
    history_query_text: '检索式',
    history_hits_text: '命中',
    history_date_text: '日期', 
    history_run_text: '运行',
    history_delete_text: '删除', 
    semantics_title_text:'语义列表',
    synonyms_text:'同义词列表', 
    source_heading: '资源',
    filter_heading: '筛选栏',
    project_title_text: '项目',
    create_project_text:'创建新的', 
    add_source_text:'添加数据源',
    add_service_text:'添加服務',
    no_project_text_line_one:'嘿，看起来您在指定期限内没有项目。',
    no_project_text_line_second: "让我们",
    no_access_text_line_one:"嘿，看起来项目不可用 ",
    no_access_text_line_second: "或无权访问此项目",
    add_data_source_line_one:"嘿，看起来项目不可用",
    add_data_source_line_second: "或无权访问此项目",
    create_new_project_text: '建立新专案',
    edit_project_text: '编辑专案',
    delete_project_text: '删除项目',
    share_project_text: '分享项目',
    back_to_home_text:'返回',
    mindmap_text: "思维导图",
    synonyms_list_text:'同义词',
    semantics_list_text: '语义学',
    taxonomy_list_text:'分类',
    taxonomy_title:'分类',
    semantics_title :'语义与语义',
    project_name_text: "项目名",
    project_files_text:'找到的文件',
    project_created_on_text: '创建于',
    project_modified_date_text: '更新時間',
    project_list_text:"项目清单",
    project_status_text: '状态', 
    project_action_text: '行动',
    new_project_text: '建立新专案',
    create_button_text: '创建',  
    update_button_text: '更新資料', 
    share_button_text: '分享',
    delete_button_text: '是的',
    project_description_text:'描述',
    eou_text:"欧亚",
    source_files_text: '资源', 
    paste_files_text: '网址',
    paste_placeholder: '在此处粘贴网址...',
    es_or_text: '或',
    search_project: '搜索项目',
    custom_field_text:'自定义字段',
    add_document_text:'添加文档',
    patent_concepts:'关键词',
    custom_fields_title: '选择自定义字段', 
    custom_search_placeholder: '搜索',
    inventor_name_text: '发明人姓名',
    inventor_company_text: '公司',
    inventor_files_text: '找到的文件',
    inventor_area_text: '热门地区',
    inventor_co_author_text: '合著者',
    synonyms_title_text: '同义字',
    semantics_text: '语义学',
    filter_fields_title: '选择过滤器字段',
    connected_source_text: '连接的数据源',
    connected_services_text:'連接服務',
    connected_path_text: '路径',
    connected_date_text: '已连接',
    connected_status_text: '状态',
    connected_action_text: '行动',
    add_data_source_text: '添加数据源',
    add_button_text: '加',
    update_source_text: "更新资料",
    source_details_placeholder: '在这里搜索文件...',
    all_sources_text: '所有来源',
    connectors_list_text: '连接器',
    list_of_files_text: '文件清单',
    connector_placeholder: '搜索数据源',
    data_source_text: '请从列表中选择一个“数据源”',
    wordform_text: '单词形式', 
    taxonomy_not_found_text: '找不到分类数据',
    semanitcs_not_found_text: '找不到语义数据',
    synonyms_not_found_text: '找不到同义词数据',
    help_text: "帮助",
    preferences_field_name : "栏位名称",
    preferences_filters: "筛选器",
    preferences_details: "细节",
    preferences_list: "清单",
    preferences_tree: "树",
    preferences_group_field: "分组",
    preferences_default_group: "默认组",
    preferences_group:"团体",
    preferences_editable: "编辑",
    preferences_edit_type:"编辑类型",
    preferences_import_editable: "导入编辑",
    preferences_patent_field: "专利领域",
    preferences_html_field:"HTML 字段",
    preferences_searchable: "搜索",
    preferences_available_list:"可用列表",
    preferences_field_type: "类型",
    preferences_ui_type: "界面类型",
    preferences_exp_src: "出口来源",
    preferences_sort_by:"排序方式",
    preferences_auto_complete: "自动完成",
    preferences_id_field:"标识字段",
    preferences_export_fields: "匯出欄位",
    preferences_group_sort: "分组排序",
    preferences_filters_list:"过滤器列表",
    update_preferences_text: "更新首選項",
    update_settings_text: "更新設置",
    update_group_sort: "更新组排序",
    update_config_text: "更新設置",
    setup_config_text:"设置配置",
    update_auto_suggetions: "更新建議",
    preferences_ui_field: "用户界面字段",
    preferences_field_def: "界面标签",
    claim_chart_list: "理赔表",
    export_to_Image: "导出为图片",
    export_to_csv_data: "导出为CSV文件",   
    xaxis_text: "X轴",
    yaxis_text:"Y轴",
    chart_type_text: "图表类型",
    select_chart_type_text: "选择图表类型",
    create_chart_text: "创建图表",
    apply_text: "生成图表",
    more_text: "更多的..", 
    select_template: "选择模板",
    copy_chart_links_to_share_text : "复制此图链接以共享",
    no_data_available_text: "没有可用数据",
    delete_from_session_text :"从会话中删除",
    save_template_text: "保存到模板",
    create_new_text:'创建新图表',
    selected_tags_text: "所选标签：",
    dashboard_nav_text: "仪表板",
    download_nav_text: "下载",
    project_nav_text: "專案",
    history_nav_text: "历史",
    alert_nav_text: "警报",
    help_nav_text: "幫助",
    field_search_text: "高级检索",
    refine_text: "筛选栏",
    analytics_title_text: "图表分析", 
    document_title_text: "文件资料", 
    wordforms_text: "字形拓展",
    semantics_text: "語義學",
    synonyms_text: "同義詞",
    highlight_text: "高亮",
    save_to_workfile_text :"保存到工作文件",
    publications_text: "公开",
    applications_text: "申请",
    families_text: "专利家族",
    custom_fields_text: "自定義字段",
    export_text : "导出",
    expand_text: "拓展",
    export_info_text : "信息",
    add_more_filters_text : "添加更多筛选条件",
    apply_all_text: "执行筛选",
    login_text : "登录",
    search_preferences : "搜索偏好选项",
    user_management :"用户管理",
    sign_out_text : "登出",
    enter_keyword_text: "输入关键字",
    enter_group_name_text: "输入群组名称",
    add_button_text: "添加",
    save_changes_button_text: "保存更改",
    new_group_text : "新组",
    enter_keywords_text: "输入关键字",
    each_new_line_text: "*每行都将被视为一个单词",
    save_button_text : "保存",
    cancel_button_text : "取消",
    back_button_text: "Back",
    clear_button_text: "清除",
    no_hidden_citations_found_text : "找不到隐藏的引文",
    wildcard_search_text:"通配符搜索",
    i_text : "i",
    total_text: "全部的",
    select_template_text : "選擇模板",
    no_data_available_text: "沒有可用數據",
    save_template_text: "保存到模板",
    chart_type_text:  "图表类型：",
    customize_analytics_text: "自定义分析",
    dolcera_templates_text: "Dolcera模板",
    user_templates_text: "用戶模板",
    new_template_text: "新模版",
    template_name_text: "模板名称：",
    save_template_text: "保存模板",
    do_you_want_to_delete_the_chart_text: "您要删除图表吗",
    from_a_template_text: "从模板？",
    do_you_want_to_delete_the_template_text : "您要删除模板吗？",
    load_template_text : "加载模板",
    save_chart_text : "保存图表",
    reduce_by_text : "减少",
    missing_patents_text : "专利缺失",
    patents_in_query_text: "查询专利",
    replace_with_text: "用。。。來代替",
    semantics_text: "語義學",
    create_new_chart_text: "創建新的",
    synonyms_text: "同義詞",
    wordforms_heading_text: "字形",
    copy_chart_links_to_share_text : "複製此圖錶鍊接以共享",
    to_text: "To",
    related_concepts_text: "相关概念：",
    reduce_by_full_text: "按申请/专利家族减少，可能有1％的错误率",
    reduce_by_is_allowed_only_for_less_than_text : "减少量仅允许在",
    patents_ref_text: "專利",
    family_text : "专利家族",
    application_text: "应用",
    patents_title_text: "专利",
    reduce_results_by_text: "减少结果",
    show_filter_query_text: "显示过滤查询",
    cpc_taxonomy_text : "CPC分类",
    cluster_cloud_text : "集群云分类",
    download_text:"下载",
    applied_tags_text: "应用标签",
    selected_tags_text: "所選標籤：",
    selected_project_text: "选定的项目：",
    prior_art_search_title_text: "现有技术搜索",
    dashboards_header_text: "儀表板",
    no_dashboards_text : "没有可用的仪表板。",
    downloads_header_text: "資料下載",
    no_downloads_text: "没有可用的下载。",
    projects_back_button_text: "專案",
    queries_text: "查询",
    workfiles_text: "工作档案",
    new_text: "新的",
    edit_text: "编辑图表",
    delete_text: "刪除",
    share_text: "分享",
    select_all_text: "全选",
    copy_text: "复制",
    combine_text: "组合",
    id_text: "ID",
    hits_text: "命中数：",
    run_on_newtab_text: "在Newtab上运行",
    run_text: "跑",
    query_text: "检索式：",
    filters_text: "筛选条件", 
    filters_title_text: "筛选器",
    document_filter_text: "文档",
    taxonomy_text: "分类",
    date_text: "日期：",
    project_name_text: "項目名",
    do_you_want_to_delete_the_project_text: "您要删除项目吗",
    select_user_text: "选择用户",
    selected_query_id_text: "選擇查詢ID",
    selected_query_ids_text: "所选检索式ID",
    query_name_text: "查询名称",
    do_you_want_to_delete_queries_text: "您要删除检索式吗",
    query_title_text: "查询标题",
    options_text: "选项",
    existing_text: "现存的",
    new_project_text: "新项目",
    clone_text: "克隆",
    query_history_text: "查询历史",
    selected_queries_text: "选择的检索式",
    active_alerts_text: "活跃警报",
    name_text: "姓名",
    description_text: "描述",
    alert_type_text: "警报类型",
    no_alerts_available_text: "没有可用的警报。",
    created_date_text: "创建日期",
    expiration_date: "截止日期",
    notification_method_text: "通知方式",
    email_text : "電子郵件",
    frequency_text: "頻率",
    do_you_want_to_delete_the_alert_text: "您是否要删除警报",
    alert_text : "警报",
    describe_the_alert_text: "描述警报",
    notification_method_and_frequency_text : "通知方法和频率",
    every_text: "每",
    week_on_text: "星期",
    month_on_text: "月",
    on_updates_text : "关于更新",
    select_alert_type_text : "*选择警报类型",
    new_publication_text : "新刊物",
    new_family_text : "新专利家庭",
    new_assignee_text: "新受让人",
    new_forward_citations_text : "新的正向引文",
    new_etst_declaration_text: "新的ETSI声明",
    new_fda_patents_text : "FDA新专利",
    reassignment_alerts_text : "重置警报",
    reassignment_text: "重新分配",
    assignor_text : "转让人",
    assignee_text : "受让人",
    legal_status_based_text: "基于法律地位",
    grants_text : "专利授权",
    lapses_text: "专利失效",
    expired_patents_text : "专利过期",
    withdrawn_patents_text : "撤销专利",
    opposition_text: "反对",
    term_extensions_text: "扩展",
    active_text: "有效",
    inactive_text: "失效",
    notification_method_text: "通知方式",
    filter_text: "筛选",
    receipent_list_text : "收件人清单",
    no_workfiles_created_text: "没有创建/分配工作文件",
    workfile_name_text: "工作档案名称",
    do_you_want_to_delete_the_workfile_text: "您是否要删除工作文件",
    highlighting_text: "高亮显示",
    collapse_all_text: "全部收起",
    expand_all_text: "展开全部",
    failed_text : "失败的",
    select_taxonomy_text : "选择分类法",
    new_dashboard_text: "新仪表板",
    existing_dashboard_text : "现有仪表板",
    dashboard_name_text: "仪表板名称",
    deselect_all_text: "取消全部选择",
    select_all_text: "全選",
    cluster_cloud_taxonomy_text: "集群云分类法",
    add_new_category_text: "新增类别",
    file_name_text: "文档名称",
    select_fields_text: "选择字段",
    reduce_by_content_text : "减少",
    document_text : "文檔",
    select_report_format_text: "选择报告格式",
    excel_text : "电子表格",
    csv_text: "CSV",
    taxonomy_sheet_text: "分类表",
    highlighting_options_text : "高亮显示选项",
    highlight_search_terms_text: "高亮搜索词",
    highlight_custom_terms_text: "高亮自定义术语",
    export_highlighting_fields_text : "高亮显示字段",
    existing_workfile_text : "现有工作档案",
    new_workfile_text : "新工作档案",
    important_semantics_text: "重要语义",
    citations_text : "引文",
    family_members_text : "专利家族",
    validate_patent_number_text : "验证专利号",
    interesting_startups_text: "初创企业",
    investors_list_text: "投资者名单",
    sort_text : "种类：",
    year_text: "年",
    no_of_patents_text : "专利号",
    foundation_year_text : "建立时间",
    investors_text: "投资人",
    enter_the_reference_patent_number_text: "输入参考专利号",
    submit_text: "提交",
    no_reference_patent_selected_text: "未选择参考专利",
    hidden_citations_selected_text : "选择隐藏的引文",
    patents_selected_text: "选择专利",
    select_all_from_current_page_text: "全选当前页面",
    exists_in_the_history_text: "存在于历史记录中",
    and_processing_text: "加工",
    meeting_code_text: "会议代码：",
    type_text : "类型：",
    meeting_start_text: "会议开始：",
    meeting_end_text : "会议结束：",
    tdoc_snippet_text : "Tdoc片段：",
    source_text: "来源：",
    agent_item_text: "Agent Item:",
    agent_item_description_text: "Agent Item Description:",
    release_text: "释放",
    version_text: "版本：",
    cr_category_text: "CR分类",
    pte_text: "PTE",
    patent_term_extended_text: "专利期限延长",
    opp_text: "OPP",
    opposition_filed_text: "异议",
    fda_text: "FDA",
    fda_orange_book_patent_text: "FDA橙皮书专利",
    rsg_text: "RSG",
    reassignement_text: "重新分配",
    max_rating_text: "最高评分",
    weightage_text: "权重",
    add_user_text: "添加用户",
    index_based_text: "基于索引",
    from_text: "从",
    assign_to_text: "分配给",
    action_text: "行動",
    do_you_want_auto_update_alert_for_this_workfile_text: "您是否要为此工作文件自动更新监控警报？",
    yes_text: "是的",
    no_text : "不",
    export_png_text: "导出为PNG文件",
    low_text: "低",
    medium_text: "中",
    high_text: "高",
    export_csv_text : "导出为CSV文件",
    publication_title_text: "出版物",
    delete_patents_text: "删除专利",
    are_you_sure_you_want_to_delete_text: "是否确定要删除",
    something_went_wrong_text: "出现了些问题",
    claim_elements_text: "权利要求要素",
    opp_re_exam_text: "OPP/RE-EXAM/APPL",
    search_terms_text: "搜索字词",
    custom_terms_text: "自定义条件",
    original_text : "Original",
    languages_text: "语言",
    priority_date_text: "优先权日期",
    priority_no_text: "优先权编号",
    application_date_text: "申请日期",
    application_no_text: "申请编号",
    publication_date_text: "发布日期",
    patent_status_text: "专利状态",
    expected_expiry: "预期到期时间",
    family_id_text: "专利家族ID",
    original_assignee_text: "原始专利权人",
    current_assignee_text: "当前专利权人",
    norm_assignee_text: "标准化专利权人",
    original_assignee_normalized_text: "标准化原始专利权人",
    inventor_text : "发明人",
    examiner_text: "审查员",
    agent_correspondent_text : "专利代理人",
    patent_citations_text: "专利引用",
    forward_citations_text: "正引用",
    non_patent_citations_text : "非专利引用",
    reassignment_history_text : "专利转让历史",
    legal_events_text: "法律事件",
    family_events_chart_text : "专利家族图表",
    external_links_text : "外部链接",
    espacenet_text: "Espacenet",
    google_patents_text: "Google专利",
    assigned_users_text: "专利许可人",
    abstract_text: "摘要",
    dolcera_rank_text: "Dolcera排名：",
    extended_members_text : "拓展专利家族",
    x_citations_text: "X引文",
    y_citations_text : "Y引文",
    class_codes_text : "分类代码",
    etsi_integration_text : "ETSI集成：",
    basis_patent_text: "基础专利：",
    declaration_company_text : "基础专利：",
    declaration_date_text: "申报日期：",
    cited_patent_text: "被引专利",
    filing_date_text : "申请日期",
    title_text : "标题",
    isld_number_text: "ISLD编号：",
    declaration_year_text: "申报年份：",
    etsi_standards_text: "ETSI标准：",
    project_text : "项目 ：",
    images_text: "图片",
    claims_text: "权利要求",
    family_to_family_citations_text : "专利家族对专利家族的引用",
    cited_by_examiner_text: "*被审查员引用，",
    cited_by_third_party_text: "†被第三方引用，",
    cited_by_applicant_text : "⟲ 被申请人引用，",
    self_citation_text: "⥀自引，",
    family_to_family_citation_text : "专利家族对专利家族的引用",
    recorded_date_text: "记录日期",
    assignors_text: "转让人",
    code_text: "代码",
    event_text: "事件",
    conveyance_text: "输送",
    active_countries_text: "有效国家",
    inactive_countries_text: "失效国家",
    patent_not_available_text: "专利不可用",
    similarity_score_text: "相似分数：",
    match_text: "％ 比赛",
    hidden_citations_text : "隐藏的引文",
    connections_text: "连接数:",
    etsi_basis_text: "ETSI基础",
    comments_text: "评论",
    rating_text: "评分",
    you_rated_it_text: "您给它打分了",
    kindly_set_ratings_parameters_in_preferences_text : "请在偏好设置中设置评级参数",
    done_text: "完毕",
    tags_text: "标签",
    merge_chart_text: "合并图表",
    apply_text: "生成图表",
    contact_text: "接触",
    back_soon_text : "我们很快回来",
    the_pcs_team_text : "— PCS团队",
    sorry_for_the_inconvenience_text: "造成您的不变，我们深表歉意。目前我们正在进行维护工作。",
    we_back_online_shortly_text: "我们将很快恢复在线",
    check_un_claimed_subject_matter_text: "检查无人认领的主题",
    recent_text: "最近的",
    check_claim_correlation_text: "检查权利要求关联性",
    export_to_sheet_text: "导出到工作表",
    export_to_dashboard_text: "导出到Dashboard",
    both_citations_text : "前引用",
    backward_citations_text : "后引用",
    your_search_did_not_match_any_documents_text : "沒有與任何文件相符的搜尋結果",
    try_different_keywords_text : "嘗試其他關鍵字",
    make_sure_that_all_words_are_spelled_correctly_text: "確保所有單詞拼寫正確",
    workfile_does_not_exist_check_workfile_id_text: "该工作文件不存在，请检查工作文件ID",
    you_do_not_have_an_access_to_this_workfile_text: "您无权访问此工作文件",
    alert_name_text: "警报名称",
    dashboard_description_text: "Dashboard说明",
    category_name_text : "分类名称",
    please_enter_file_name_text : "请输入文件名称",
    search_fields_text: "搜索字段...",
    mm_dd_yyyy_text: "月/日/年",
    add_concepts_text : "添加关键词",
    enter_own_concept_text: "输入自己的关键词",
    date_range_text: "日期范围：",
    important_concepts_text: "重要概念：",
    cpc_codes_text: "CPC分类号：",
    enter_cpc_code_text: "输入CPC分类号",
    enter_country_code_text: "输入国家代码",
    enter_rating_tag_text: "输入评分标签",
    search_workfile_text: "搜索工作文件...",
    add_description_text: "添加说明...",
    documents_title_text: "文件资料",
    score_text: "分数",
    documents_text: "文件资料",
    next_text: "下一个",
    prev_text: "上一个",
    filter_search_text : "Filter Search:",
    filter_search_placeholder : "Filter Search...!",
    chart_filters_text: "篩選條件：",
    infotiles: "信息瓷砖",
    filters: "筛选条件",
    synonyms: "同義詞",
    wordforms: "字形",
    connected_data_text : "关联数据",
    field_prefrences_data_text : "偏好设置",
    settings_data_text:"设置",
    group_sort_text:"分组排序",
    master_data_text:"主數據",
    indexed_text: "索引：",
    not_supported_text: "不支持：", 
    select_x_axis_text:"选择X轴",
    select_y_axis_text: "选择Y轴",
    select_z_axis_text: "选择Z轴",
    filter_query_text : "筛选查询",
    add_to_search_text : "添加到搜索",
    enterprise_search_title_text: "企业搜寻",
    filter_fields_text: "筛选栏",
    x_axis_text: "X轴",
    y_axis_text: "Y轴",
    z_axis_text: "Z轴" ,
    chart_info_title_text: '标题',
    chart_info_title_text_zh: '标题（中文）',
    technologies_path_text: '技术路径',
    equipment_path_text: '设备路径',
    website_text: "网站",
    files_uploader_text: "文件上传器",
    product_reports_text: "产品报告",
    enter_website_url_here_text : "在此处输入网站网址...",
    depth_text: "深度：",
    select_directory_text: "选择目录",
    enter_directory_text:"进入目录..！",
    drag_and_drop_files_text: "拖放文件",
    there_is_no_files_to_indexing_text: "沒有可用於索引的檔案。",
    there_is_no_services_to_indexing_text: "沒有可用於索引的服務。",
    reports_compare_text: "比较",
    reports_customize_text: "定制",
    reports_timeline_text: "时间轴",
    reports_competitor_text: "竞争对手",
    reports_companies_text: "选择公司",
    edit_document_text: "编辑文件",
    add_document_text:"添加文档",
    restore_to_default_text:"恢复到默认值",
    copied_image_url_enter_here_placeholder: "在此粘贴图片地址或拖拽图片到下方",
    load_more:'装载更多..',
    first_name_text: "名",
    last_name_text: "姓",
    user_email_text: "电子邮件",
    password_text: "密码",
    role_text: "角色",
    users_text: "用户",
    new_user_text: "新用户",
    reset_password: "重设密码",
    snippet_text:"相关内容",
    abstract_text:"抽象的",
    summery_text:"概括",
    landing_page_header_text:"开始使用强大的企业搜索体验",
    partnership_with: "合伙",
    all_documents_text: "查看所有资讯",
    save_as_template:"另存为模板",
    add_node_text: "添加节点",
    no_search_data_section: "沒有為您的搜索找到匹配項。",
    view_all_text:"查看全部",
    data_connectors_text:"数据连接器",
    social_connectors_text:"社交连接器",
    cloud_connectors_text:"云连接器",
    my_projects_text:"我的项目",
    no_connectors_available: "沒有可用的連接器",
    import_text: "进口",
    no_document_details: "无文件详情",
    user_manual_text: "用户手册",
    upload_files_text: "上傳文件",
    drag_and_drop_text: "將文件拖放到此區域（或）",
    sm_upload_files_text: "从电脑上传文件",
    sm_drag_and_drop_text: "或者拖放到此处（.xlsx、.xls、zip 最大 50MB）",
    browse_files_text : "瀏覽文件",
    export_to_pdf_text:"导出为 PDF",
    export_to_sheet_text:"匯出到工作表",
    close_text: "關閉",
    workfile_editable:"編輯",
    specifcation_title_text: "规范版本跟踪",
    section_number_text: "節號",
    tdoc_number_text: "斷續器編號",
    tdoc_title_text: "章節標題",
    start_version_text: "啟動版本",
    end_version_text : "結束版本",
    cr_category_text : "CR 類別",
    three_gpp_details_text: "3GPP 詳細資訊",
    top_three_gpp_contributors_text: "排名前3位的3GPP貢獻者",
    serial_number_text : "S.no",
    no_of_contributions_text : "捐款數量",
    top_etsi_declaring_companies : "ETSI頂級申報公司",
    company_text: '公司',
    preferences_info_field: "資料",
    preferences_filter_url:"过滤网址",
    details_view_text: "視圖",
    group_sort_data_title_text: "未選擇組排序選項",
    original_document_text: "原始文件",
    add_note_text: "新增註釋",
    add_alert_text: "添加警報",
    document_review_text: "文件審查",
    update_review_text: "更新評論",
    add_note_text: "Add Note",
    add_alert_text: "Add Alert",
    select_range_text: "選擇範圍",
    delete_query_text: "删除查询",
    browse_text : "瀏覽文件",
    export_to_project_title: "导出到项目",
    move_to_project_title: "移動とPロジャーCT",
    new_project_text: "新项目",
    existing_project_text : "现有项目",
    customize_fields_text : "自定义字段",
    customize_project_text: "定制项目",
    review_patent_text : "审查专利",
    under_review_patent_text : "正在审核中",
    created_by_text:"由...制作",
    no_files_available_text: "目前没有新的通知。",
    no_fields_for_mapping_text: "目前，没有可用于映射的字段。",
    please_review_config_fields_text: "请检查映射配置以确保定义所有必需的字段。",
    edit_mapping_text: "编辑映射",
    no_sheet_identified_for_mapping_text: "無法識別資料表類型",
    please_review_sheet_text: "我們無法辨識您上傳的資料表的類型。請確保格式正確，然後再次嘗試上傳。",
    granted_claims_text : "已批准的索賠",
    application_claims_text : "申請索賠",
    preferences_edit_source : "編輯來源",
    save_query_to_stored_search_title: "將查詢儲存到儲存的搜索",
    new_search_option_text: "新搜尋",
    existing_search_option_text: "現有搜尋"
}