import sampleData from './initialState';

var store = undefined;

export default {
    init(configureStore){
        store = configureStore(sampleData);
    },
    getStore(){
        return store;
    },
    getCurrentState(){
        return store.getState();
    },
    getApi(){
        var currentState = this.getCurrentState();
        return currentState.config.api;
    },
    getConfigParamsFields(){
        var currentState = this.getCurrentState();

        if(currentState.connectorScreen)
            return currentState.connectorConfigParams['fields'];
        else
            return currentState.configParams['fields'];
    },
    getConfigParamsRawFields(){
        var currentState = this.getCurrentState();
        return currentState.configParams['raw'];
    },
    getSettingsFields(){
        var currentState = this.getCurrentState();
        return currentState.configParams['settings'];
    },
    getConnectorConfigParamsRawFields(){
        var currentState = this.getCurrentState();
        return currentState.connectorConfigParams['raw'];
    },
    getDefaultField(){
        var currentState = this.getCurrentState();
        let rawFields = currentState.configParams['raw'];

        if(currentState.connectorScreen)
            rawFields = currentState.connectorConfigParams['raw'];

        return (rawFields && rawFields['df']) ? rawFields['df'] : '';
    },
    getConfigParamsOperators(){
        var currentState = this.getCurrentState();
        return currentState.configParams['operators'];
    },
    getPatentsCount(){
        var currentState = this.getCurrentState();
        return currentState.chartsOverviewData['numFound'];
    },
    getAppConfig(){
        var currentState = this.getCurrentState();
        return currentState.config;
    },
    getQueryMetaData(){
        var currentState = this.getCurrentState();
        return currentState.queryMetaData;
    },
    getPatentCustomFields(){ // TO get Patent Custom Fields
        var currentState = this.getCurrentState();
        return currentState.customizePatentDataStatus;
    },
    getGroupSort(){ // TO get Group Sort
        var currentState = this.getCurrentState();
        return currentState.groupSort;
    },
    getaddUserSynonymsFlag(){ // TO get addUserSynonymsFlag
        var currentState = this.getCurrentState();
        return currentState.addUserSynonymsFlag;
    },
    getPreferences(){
        var currentState = this.getCurrentState();
        return currentState.config.preferences ? currentState.config.preferences : {};
    },
    getsessionGroupData(){ // TO get session based user groups
        var currentState = this.getCurrentState();
        return currentState.sessionGroupData;
    },
    getProjectId(){
        var currentState = this.getCurrentState();
        return currentState.projectId;
    },
    getApiServicesStatus(){
        var currentState = this.getCurrentState();
        return currentState.apiServicesStatus;
    },
    getCurrentLanguage(){
        var currentState = this.getCurrentState();
        return currentState.language;
    },
    getAppliedSimilarityFilters(){
        var currentState = this.getCurrentState();
        return currentState.appliedSimilarityFilters;
    },
    getUrlFilters(){
        var currentState = this.getCurrentState();
        return currentState.urlFilters;
    },
    getApiContentType(){
        var currentState = this.getCurrentState();
        return currentState.config.apiHeader;
    },
    getConnectorInfo(){
        var currentState = this.getCurrentState();
        return currentState.connectorInfo;
    },
    getFieldQuery(){
        var currentState = this.getCurrentState();
        return currentState.fieldQuery;
    },
    getResultCount(){
        var currentState = this.getCurrentState();
        return (currentState.patentsData && currentState.patentsData.page_1 && currentState.patentsData.page_1 && currentState.patentsData.page_1.numFound) ? currentState.patentsData.page_1.numFound : 0;
    },
    getSessionKey(){
        var currentState = this.getCurrentState();
        return currentState.config.sessionKey;
    },
    getAlertsStatusPath(){
        var currentState = this.getCurrentState();
        return currentState.alertsStatusPath;
    },
    getAppliedStoredSearch(){
        var currentState = this.getCurrentState();
        return currentState.appliedStoredSearch;
    }

};